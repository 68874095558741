
































































import { Vue, Component } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CreateGradeDeProdutosUseCase, UpdateGradeDeProdutosUseCase } from '@/usecases'
import { GradeDeProdutos, ItemGradeDeProdutos } from '@/models'
import { v4 as uuidv4 } from 'uuid'

@Component

export default class DialogoDeEdicaoDeGradeDeProdutos extends Vue {
	gradeDeProdutos = {id:"", descricao:""} as GradeDeProdutos
	mostra = false
	salvando = false
	createGradeDeProdutosUseCase = new CreateGradeDeProdutosUseCase()
	updateGradeDeProdutosUseCase = new UpdateGradeDeProdutosUseCase()

	mostrar(gradeDeProdutos) {
		this.mostra = true
		this.gradeDeProdutos = gradeDeProdutos

		this.gradeDeProdutos.itens.sort((itemA, itemB) => itemA.ordem - itemB.ordem)
	}

	esconder() {
		this.mostra = false
		this.$emit('fecharDialogo')
	}

	async salvar() {
		if (!this.gradeDeProdutos) return
		if (!this.gradeDeProdutos.descricao || this.gradeDeProdutos.descricao.trim() === '') {
			AlertModule.setError('Informe uma descição para essa grade')
			return
		}
		
		try {
			this.salvando = true

			const ordemDuplicada = [] as number[]
			const ordemUnica = [] as number[]

			this.gradeDeProdutos.itens.map(item => item.ordem = Number(item.ordem))

			this.gradeDeProdutos.itens.forEach(item => {
				if (ordemUnica.includes(item.ordem)) {
					const indice = ordemUnica.findIndex(ordemUnicaItem => ordemUnicaItem === item.ordem)
					ordemUnica.splice(indice, 1)
					if (!ordemDuplicada.includes(item.ordem)) {
						ordemDuplicada.push(item.ordem)
					}
				}

				if (!ordemUnica.includes(item.ordem) && !ordemDuplicada.includes(item.ordem)) {
					ordemUnica.push(item.ordem)
				}
			})

			if (ordemDuplicada.length > 0) {
				throw Error('Há ordens de prioridade duplicadas, verifique!')
			}

			const gradeDeProdutos = this.gradeDeProdutos.id
				? await this.updateGradeDeProdutosUseCase.update(this.gradeDeProdutos)
				: await this.createGradeDeProdutosUseCase.create(this.gradeDeProdutos)

			this.$emit('confirmado', gradeDeProdutos)
			this.mostra = false
			AlertModule.setSuccess('Grade de produtos salva com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	remover(indice: number) {
		this.gradeDeProdutos.itens.splice(indice, 1)
	}

	adicionarNovoTamanho() {
		if (!this.gradeDeProdutos) return

		this.gradeDeProdutos.itens.push(
			{
				id: uuidv4(),
				descricaoItem: '',
				ordem: this.gradeDeProdutos.itens.length || 0,
			} as ItemGradeDeProdutos,
		)
	}
}
