





















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import DialogoDeEdicaoDeGradeDeProdutos from '@/components/produto/DialogoDeEdicaoDeGradeDeProdutos.vue'
import { GradeDeProdutos, Page } from '@/models'
import { CancelToken } from 'axios'
import { Pageable } from '@/models/Pageable'
import { FindGradeDeProdutosUseCase } from '@/usecases'

@Component({
	components: {
		TelaGenerica,
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeGradeDeProdutos,
	},
})

export default class TelaDeGradeDeProduto extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeGradeDeProdutos

	findUseCase = new FindGradeDeProdutosUseCase()
	grades: GradeDeProdutos[] = []
	busca = ''
	carregando = false
	editando = false

	headers = [
		{ text: 'Grade', value: 'descricao' },
	]

	abrirDialogoDeEditarGrade(indice: number) {
		this.editando = true
		const grade = this.grades[indice]
		this.dialogoDeEdicao.mostrar(grade)
	}

	abrirDialogoDeCriarGrade() {
		this.editando = false
		const grade = {
			id:'',
			descricao:'',
			itens: [],
		}
		this.dialogoDeEdicao.mostrar(grade)
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<GradeDeProdutos>> {
		const pagina = await this.findUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.grades = pagina.content
		return pagina
	}

	gradeSalva(gradeSalva) {
		const indice = this.grades.findIndex(grade => grade.id === gradeSalva.id)

		if (indice === -1) {
			this.grades.push(gradeSalva)
		} else {
			this.grades.splice(indice, 1, gradeSalva)
		}
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
